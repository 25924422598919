"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _TextButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/TextButton"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _grid = require("@rebass/grid");
var _formik = require("formik");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _SearchPlacesModal = _interopRequireDefault(require("./SearchPlacesModal"));
var _PlusIcon = _interopRequireDefault(require("./svg/PlusIcon.svg"));
var _Divider = _interopRequireDefault(require("./Divider"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var _TenantContext = require("../context/TenantContext");
var __jsx = _react.default.createElement;
const PlacesFilter = props => {
  const {
    open,
    onOpen,
    onClose
  } = (0, _useSesame.default)(false);
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const {
    values: {
      orFilters,
      andFilters
    }
  } = (0, _formik.useFormikContext)();
  const numberOfSelectedPlaces = brandConfig.placeFilter?.type !== 'AND' ? orFilters ? orFilters.filter(filter => filter.startsWith('_place')).length : 0 : andFilters ? andFilters.filter(filter => filter.startsWith('_place')).length : 0;
  // Hide the filter if it is disabled in the brandConfig, should be explicitly set to false
  if (brandConfig.placeFilter?.show === false) {
    return null;
  }
  return __jsx(_react.default.Fragment, null, __jsx(_grid.Flex, {
    my: 2,
    flexDirection: "column"
  }, __jsx(_Body.default, {
    variant: "micro",
    style: {
      fontWeight: 'bold',
      marginBottom: '0.8rem'
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "etjfNt",
    defaultMessage: [{
      "type": 0,
      "value": "Plaats"
    }]
  })), __jsx(PlacesFilterButton, {
    size: "tiny",
    onClick: onOpen,
    hasPlacesSelected: !!numberOfSelectedPlaces,
    hideBorderOnFocus: true
  }, numberOfSelectedPlaces > 0 ? __jsx(_reactIntl.FormattedMessage, {
    id: "coPzjf",
    defaultMessage: [{
      "type": 0,
      "value": "Plaatsen ("
    }, {
      "type": 1,
      "value": "places"
    }, {
      "type": 0,
      "value": ")"
    }],
    values: {
      places: numberOfSelectedPlaces
    }
  }) : __jsx(_react.default.Fragment, null, __jsx(_PlusIcon.default, {
    width: 16,
    height: 16
  }), __jsx(_reactIntl.FormattedMessage, {
    id: "jPZ5mC",
    defaultMessage: [{
      "type": 0,
      "value": "Plaats toevoegen"
    }]
  })))), __jsx(_Divider.default, null), __jsx(_SearchPlacesModal.default, (0, _extends2.default)({
    open: open,
    onClose: onClose
  }, props)));
};
var _default = exports.default = PlacesFilter;
const PlacesFilterButton = (0, _styledComponents.default)(_TextButton.default).withConfig({
  displayName: "PlacesFilter__PlacesFilterButton",
  componentId: "sc-1mcadxl-0"
})(["color:", ";justify-content:flex-start;display:inline-block;max-width:", ";span{font-weight:600;svg{margin:0 1.2rem 0.2rem 0.4rem;}align-items:center;}&:hover{color:", ";span::after{border:none;}}@media screen and (max-width:", "){padding:1rem;border:1px solid ", ";box-shadow:0px 1.6rem 2.4rem 0 rgba(59,118,160,0.03),0 2.4rem 8rem 0 rgba(59,118,160,0.05);border-radius:0.8rem;}transition:color 0.15s ease-in-out;"], ({
  theme
}) => theme.colors.neutral['70'], ({
  hasPlacesSelected
}) => hasPlacesSelected ? '10rem' : 'none', ({
  theme
}) => (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.textAction), ({
  theme
}) => theme.mediaQueries.m, ({
  theme
}) => theme.colors.neutral['20']);