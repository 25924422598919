"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fragment = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _devicesize = require("@oberoninternal/travelbase-ds/context/devicesize");
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _grid = require("@rebass/grid");
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _lodash = _interopRequireDefault(require("lodash.isequal"));
var _router = require("next/router");
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _reactLoadingSkeleton = _interopRequireDefault(require("react-loading-skeleton"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _searchParams = _interopRequireWildcard(require("../constants/searchParams"));
var _tripTypeTexts = _interopRequireDefault(require("../constants/tripTypeTexts"));
var _TenantContext = require("../context/TenantContext");
var _graphql = require("../generated/graphql");
var _useAnalyticsContentCategory = _interopRequireDefault(require("../hooks/analytics/useAnalyticsContentCategory"));
var _useComposeFilterCategory = _interopRequireDefault(require("../hooks/search/useComposeFilterCategory"));
var _useFiltersActive = _interopRequireDefault(require("../hooks/useFiltersActive"));
var _usePrevious = _interopRequireDefault(require("../hooks/usePrevious"));
var _useQueryParams = _interopRequireDefault(require("../hooks/useQueryParams"));
var _getDefaultAccommodationType = _interopRequireDefault(require("../utils/getDefaultAccommodationType"));
var _search = require("../utils/search");
var _searchBoxValuesFromStorage = require("../utils/searchBoxValuesFromStorage");
var _trip = require("../utils/trip");
var _AccommodationSearchFilters = _interopRequireDefault(require("./AccommodationSearchFilters"));
var _ContentWrapper = _interopRequireDefault(require("./ContentWrapper"));
var _Heading = _interopRequireDefault(require("./designsystem/Heading"));
var _FilterList = _interopRequireDefault(require("./FilterList"));
var _Formed = _interopRequireDefault(require("./Formed"));
var _Page = _interopRequireDefault(require("./Page"));
var _SearchBar = _interopRequireDefault(require("./SearchBar"));
var _SearchBottomBar = _interopRequireDefault(require("./SearchBottomBar"));
var _SearchFiltersModal = _interopRequireDefault(require("./SearchFiltersModal"));
var _SearchFilterTags = _interopRequireDefault(require("./SearchFilterTags"));
var _SearchForm = require("./SearchForm");
var _SearchSortModal = _interopRequireDefault(require("./SearchSortModal"));
var _ViewToggle = _interopRequireDefault(require("./designsystem/ViewToggle"));
var _SearchOrderSelectInput = _interopRequireDefault(require("./SearchOrderSelectInput"));
var _debounce = require("debounce");
var _Usps = _interopRequireDefault(require("./Usps"));
var _lodash2 = _interopRequireDefault(require("lodash.pick"));
var _Booking = require("../entities/Booking");
var _queryString = require("query-string");
const _excluded = ["ResultsComponent", "amountPerPage", "contentWrapperVariant", "buttonTypes", "bakedInParams", "defaultAccommodationType", "startParams", "ignoreStorage", "totalHits", "tripTypeCounts", "propertyFilterCounts", "specialCounts", "loading", "data", "searchOrder", "lastSearchParams", "minPrice", "maxPrice"],
  _excluded2 = ["offset", "accommodationType", "order", "andFilters", "orFilters", "tripType", "specialId", "minPrice", "maxPrice", "minimalMinPrice", "maximalMaxPrice", "bbLeft", "bbRight", "bbTop", "bbBottom"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const fragment = exports.fragment = (0, _graphqlTag.default)`
    fragment UnitFilterPropertyCategory on UnitFilterPropertyCategory {
        name
        handle
        filterProperties {
            name
            handle
        }
    }
`;
const getSpecialId = values => {
  const entry = values.specials[0];
  if (!entry) {
    return undefined;
  }
  return entry;
};
const searchFilterKeys = ['andFilters', 'orFilters', 'specials', 'price', 'tripType'];
const SearchResults = _ref => {
  let {
      ResultsComponent,
      amountPerPage,
      contentWrapperVariant,
      buttonTypes,
      bakedInParams = {},
      defaultAccommodationType,
      startParams = {},
      ignoreStorage = false,
      totalHits = 0,
      tripTypeCounts = [],
      propertyFilterCounts = [],
      specialCounts,
      loading,
      data,
      searchOrder = {},
      lastSearchParams,
      minPrice,
      maxPrice
    } = _ref,
    rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const [params, setParams] = (0, _useQueryParams.default)(_searchParams.default);
  const initialStorageVals = !ignoreStorage ? (0, _searchBoxValuesFromStorage.getSearchBoxValuesFromStorage)() : undefined;
  const pageRef = (0, _react.useRef)(null);
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const router = (0, _router.useRouter)();
  const _withDefaultBookingVa = (0, _trip.withDefaultBookingValues)(
    // merge initial params and current params and overwrite it with bakedIn params
    (0, _search.mergeParams)(startParams, initialStorageVals, params, bakedInParams, brandConfig.bakedInFilterProperty), brandConfig),
    {
      offset = 0,
      accommodationType = defaultAccommodationType ?? (0, _getDefaultAccommodationType.default)(brandConfig),
      order = rest.order ?? searchOrder.popularity ?? null,
      andFilters = [],
      orFilters = [],
      tripType,
      specialId,
      minPrice: filterMinPrice,
      maxPrice: filterMaxPrice,
      minimalMinPrice,
      maximalMaxPrice,
      bbLeft,
      bbRight,
      bbTop,
      bbBottom
    } = _withDefaultBookingVa,
    booking = (0, _objectWithoutProperties2.default)(_withDefaultBookingVa, _excluded2);
  const filterModal = (0, _useSesame.default)();
  const sortModal = (0, _useSesame.default)();
  const searchBarValues = (0, _react.useMemo)(() => ({
    booking,
    accommodationType
  }), [accommodationType, booking]);
  const prevSearchBoxValues = (0, _usePrevious.default)(searchBarValues);
  (0, _react.useEffect)(() => {
    // update localstorage with new searchbar values if they've changed
    if (!(0, _lodash.default)(prevSearchBoxValues, searchBarValues)) {
      (0, _searchBoxValuesFromStorage.setSearchBoxValuesToStorage)(searchBarValues);
    }
  }, [prevSearchBoxValues, searchBarValues]);
  (0, _useAnalyticsContentCategory.default)('accommodations');

  // we check if there are filters active in order to show a "remove filters" button
  const [filtersActive, removeActiveFilters] = (0, _useFiltersActive.default)(_searchParams.searchFilterParams);

  // TODO(jari): fix
  // useAnalyticsUnitViewList(data?.searchTrips.hits ?? []);

  // if the filter is bakedin, we don't want to show it in the ui
  const filterPropertyIsBakedIn = (0, _react.useCallback)(handle => {
    const predicate = filter => filter.startsWith(handle);
    return !!bakedInParams.orFilters?.some(predicate) || !!bakedInParams.andFilters?.some(predicate);
  }, [bakedInParams.andFilters, bakedInParams.orFilters]);
  const {
    data: unitFilterPropertiesData,
    loading: filtersLoading
  } = (0, _graphql.useUnitFilterPropertiesQuery)({
    ssr: false
  });
  const allProperties = (0, _react.useMemo)(() => {
    if (brandConfig.placeFilter?.hasPriority) {
      return unitFilterPropertiesData?.unitFilterProperties.filter(property => !filterPropertyIsBakedIn(property.handle)).sort((a, b) => {
        if (a.handle === '_place') {
          return -1;
        }
        if (b.handle === '_place') {
          return 1;
        }
        return 0;
      });
    }
    return unitFilterPropertiesData?.unitFilterProperties.filter(property => !filterPropertyIsBakedIn(property.handle));
  }, [brandConfig.placeFilter?.hasPriority, filterPropertyIsBakedIn, unitFilterPropertiesData?.unitFilterProperties]);
  const pageCount = totalHits && Math.ceil(totalHits / amountPerPage);
  const currentPageIndex = Math.floor(offset / amountPerPage);
  const baseMinPrice = filterMinPrice ?? minPrice ?? 1;
  const baseMaxPrice = filterMaxPrice ?? maxPrice ?? 20000;
  const propertiesAvailable = !!propertyFilterCounts && propertyFilterCounts?.length > 0;
  const prevBooking = (0, _react.useRef)(null);
  const hidePriceFilter = !propertiesAvailable || typeof minPrice !== 'number' || typeof maxPrice !== 'number';
  const getActiveSearchbar = (0, _react.useCallback)(() => {
    if (brandConfig.searchBar?.active) {
      return brandConfig.searchBar.active;
    }
    return _SearchForm.interactables.filter(interactable => {
      if (bakedInParams.accommodationType) {
        return interactable !== 'accommodationType';
      }
      return true;
    });
  }, [bakedInParams.accommodationType, brandConfig]);
  const handleSubmit = (0, _react.useCallback)((values, helpers) => {
    if (!prevBooking.current) {
      prevBooking.current = values.booking;
    }
    const [start, end] = values.price;
    const singlePrice = start === end;
    const newParams = _objectSpread({
      andFilters: values.andFilters,
      orFilters: values.orFilters,
      tripType: values.tripType,
      specialId: getSpecialId(values),
      accommodationType: values.accommodationType,
      offset: 0,
      order: values.order,
      minPrice: singlePrice || start === minimalMinPrice ? undefined : start,
      maxPrice: singlePrice || end === maximalMaxPrice ? undefined : end
    }, values.booking);

    // did booking not change?
    if ((0, _lodash.default)(prevBooking.current, values.booking) && (start !== minPrice || end !== maxPrice)) {
      if (!minimalMinPrice) {
        newParams.minimalMinPrice = minPrice;
      }
      if (!maximalMaxPrice) {
        newParams.maximalMaxPrice = maxPrice;
      }
    } else {
      // if booking changed, delete all price filters
      newParams.minimalMinPrice = undefined;
      newParams.maximalMaxPrice = undefined;
      newParams.minPrice = undefined;
      newParams.maxPrice = undefined;
    }

    // by changing the params, apollo will refetch the query automagically
    setParams(newParams, 'replaceIn');
    setTimeout(() => helpers.setSubmitting(false));
    prevBooking.current = values.booking;
  }, [maxPrice, maximalMaxPrice, minPrice, minimalMinPrice, setParams]);
  const deviceSize = (0, _devicesize.useDeviceSize)();
  const shouldAutoSave = deviceSize !== 'mobile';
  const unitFilterCategories = (0, _react.useMemo)(() => (propertyFilterCounts && allProperties?.filter(Boolean).reduce((acc, next) => {
    if (filterPropertyIsBakedIn(next.handle)) {
      return acc;
    }
    return [...acc, (0, _useComposeFilterCategory.default)({
      categoryHandle: next.handle,
      categoryLabel: next.name,
      filterCounts: propertyFilterCounts,
      filterCountKey: 'property',
      filterProperties: next.filterProperties,
      filterResolver: ({
        handle,
        name
      }) => ({
        handle: `${next.handle}.${handle}`,
        label: name
      }),
      formikKey: next.handle === '_place' && brandConfig.placeFilter?.type !== 'AND' ? next.handle.startsWith('_') ? 'orFilters' : 'andFilters' : 'andFilters'
    })];
  }, [])) ?? [], [allProperties, brandConfig.placeFilter?.type, filterPropertyIsBakedIn, propertyFilterCounts]);
  const tripTypeCategory = (0, _react.useMemo)(() => {
    const tripTypes = Object.entries(_tripTypeTexts.default).map(([key, value]) => ({
      label: value,
      handle: key
    }));
    return (0, _useComposeFilterCategory.default)({
      categoryHandle: 'tripType',
      filterCounts: tripTypeCounts ?? [],
      filterProperties: tripTypes,
      filterCountKey: 'tripType'
    });
  }, [tripTypeCounts]);
  const specialsCategory = (0, _react.useMemo)(() => ({
    handle: 'specials',
    filters: specialCounts?.map(({
      special,
      hits
    }) => ({
      handle: special.id,
      label: special.name,
      hits
    }))
  }), [specialCounts]);
  const getActiveSpecial = selectedSpecial => specialCounts?.find(specialCount => specialCount.special.id === selectedSpecial);
  const unitParams = (0, _react.useMemo)(() => _objectSpread(_objectSpread({}, (0, _lodash2.default)(booking, _Booking.bookingCartKeys)), {}, {
    accommodationType,
    searched: router.asPath.split('?')[1]
  }), [accommodationType, booking, router.asPath]);
  const handleAutoSave = (0, _react.useMemo)(() => (0, _debounce.debounce)(handleSubmit), [handleSubmit]);
  const {
    onMapClick = () => {
      router.push({
        pathname: `/search/map`,
        search: router.asPath.split('?')[1]
      });
    },
    onListClick = () => {
      // remove bounding box.
      const search = _objectSpread({}, router.query);
      delete search.bbLeft;
      delete search.bbTop;
      delete search.bbRight;
      delete search.bbBottom;
      router.push({
        pathname: `/search`,
        search: (0, _queryString.stringify)(search)
      });
    }
  } = rest;
  const accommodationSearchFilters = (0, _react.useMemo)(() => propertiesAvailable && __jsx(_AccommodationSearchFilters.default, {
    propertyFilterCounts: propertyFilterCounts ?? [],
    allProperties: allProperties,
    hidePriceFilter: hidePriceFilter,
    maximalMaxPrice: maximalMaxPrice ?? maxPrice ?? 1,
    minimalMinPrice: minimalMinPrice ?? minPrice ?? 20000,
    specialCategories: [specialsCategory, tripTypeCategory],
    unitFilterCategories: unitFilterCategories
  }), [propertiesAvailable, propertyFilterCounts, allProperties, hidePriceFilter, maximalMaxPrice, maxPrice, minimalMinPrice, minPrice, specialsCategory, tripTypeCategory, unitFilterCategories]);
  const initialValues = {
    booking,
    accommodationType,
    offset,
    orFilters,
    andFilters,
    tripType: tripType && !bakedInParams.tripType ? tripType : [],
    order: rest.order ?? order,
    specials: specialId ? [specialId] : [],
    price: [baseMinPrice, baseMaxPrice]
  };
  return __jsx(_react.default.Fragment, null, __jsx(_Page.default, {
    ref: pageRef,
    id: "search-page"
  }, brandConfig.showUsps && __jsx(_Usps.default, {
    className: "search-usps"
  }), __jsx(_ContentWrapper.default, {
    variant: contentWrapperVariant
  }, __jsx(_Formed.default, {
    skipPrompt: true,
    initialValues: _objectSpread({}, initialValues),
    handleSubmit: handleSubmit,
    enableReinitialize: true
  }, () => __jsx(_SearchBar.default, {
    variant: contentWrapperVariant === 'searchList' ? 'searchpage' : 'searchMap',
    hideSearchIcon: true,
    active: getActiveSearchbar()
  })), __jsx(_Formed.default, {
    skipPrompt: true,
    handleAutoSave: shouldAutoSave ? (values, helpers) => {
      helpers.setSubmitting(true);
      handleAutoSave(values, helpers);
    } : undefined,
    initialValues: _objectSpread({}, initialValues),
    handleSubmit: handleSubmit,
    enableReinitialize: true
  }, ({
    isSubmitting,
    values
  }) => {
    const selectedSpecial = getSpecialId(values);
    const activeSpecial = getActiveSpecial(selectedSpecial);
    const isLoading = loading || isSubmitting;
    // we show a skeleton when filters are actually loading or when the search results are loading and there aren't any filter properties available;
    const showFilterLoader = filtersLoading || isLoading && !propertiesAvailable;
    const accommodationHitsMessage = totalHits ? __jsx(_reactIntl.FormattedMessage, {
      id: "zhKkRH",
      defaultMessage: [{
        "type": 1,
        "value": "hits"
      }, {
        "type": 0,
        "value": " "
      }, {
        "type": 6,
        "pluralType": "cardinal",
        "value": "totalHits",
        "offset": 0,
        "options": {
          "one": {
            "value": [{
              "type": 0,
              "value": "accommodatie"
            }]
          },
          "other": {
            "value": [{
              "type": 0,
              "value": "accommodaties"
            }]
          }
        }
      }],
      values: {
        hits: __jsx(HitsCount, null, totalHits),
        totalHits
      }
    }) : null;
    return __jsx(_grid.Flex, {
      flexDirection: ['column', null, null, 'row']
    }, __jsx(LeftBar, {
      className: contentWrapperVariant === 'searchMap' ? 'gt-m' : undefined
    }, __jsx(_Heading.default, {
      className: "gt-m"
    }, isLoading && __jsx(_grid.Box, null, __jsx(_reactLoadingSkeleton.default, {
      height: "1.5em",
      width: 250
    })), !isLoading && __jsx(_Title.default, {
      style: {
        fontWeight: 400
      }
    }, accommodationHitsMessage)), __jsx(_SearchFilterTags.default, {
      shouldAutoSave: shouldAutoSave,
      categories: [tripTypeCategory, specialsCategory, ...unitFilterCategories]
    }), __jsx(_grid.Box, {
      className: "gt-m"
    }, showFilterLoader && __jsx(_FilterList.default, {
      loading: true
    }), !showFilterLoader && accommodationSearchFilters)), !isLoading && contentWrapperVariant !== 'searchMap' && __jsx(_Body.default, {
      className: "lt-s",
      variant: "large",
      style: {
        fontWeight: 400,
        marginTop: '2.4rem'
      }
    }, accommodationHitsMessage), __jsx(_SearchBottomBar.default, {
      allowedActions: buttonTypes,
      filtersActive: filtersActive,
      onMapClick: onMapClick,
      onListClick: onListClick,
      onFiltersClick: filterModal.onOpen,
      onSortClick: sortModal.onOpen
    }), __jsx(_SearchFiltersModal.default, {
      dirtyCheckKeys: searchFilterKeys,
      filtersActive: filtersActive,
      removeActiveFilters: removeActiveFilters,
      onClose: filterModal.onClose,
      open: filterModal.open
    }, accommodationSearchFilters), __jsx(_SearchSortModal.default, {
      searchOrder: searchOrder,
      onClose: sortModal.onClose,
      open: sortModal.open
    }), __jsx(ResultsContainer, null, buttonTypes.length > 0 && __jsx(RightItems, {
      className: "gt-m"
    }, buttonTypes.includes('sort') && __jsx(_SearchOrderSelectInput.default, {
      searchOrder: searchOrder
    }), (buttonTypes.includes('map') || buttonTypes.includes('list')) && __jsx(_ViewToggle.default, {
      allowMap: buttonTypes.includes('map'),
      allowList: buttonTypes.includes('list'),
      onMap: onMapClick,
      onList: onListClick
    })), __jsx(ResultsComponent, {
      data: data,
      isLoading: isLoading,
      lastSearchParams: lastSearchParams,
      currentPageIndex: currentPageIndex,
      pageCount: pageCount ?? 0,
      activeSpecial: bakedInParams.tripType?.[0] ?? activeSpecial,
      unitParams: unitParams,
      filtersActive: filtersActive,
      removeActiveFilters: removeActiveFilters,
      searchType: booking.type
    })));
  }))));
};
var _default = exports.default = SearchResults;
const HitsCount = _styledComponents.default.span.withConfig({
  displayName: "SearchResults__HitsCount",
  componentId: "sc-184iqnz-0"
})(["color:inherit;"]);
const LeftBar = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "SearchResults__LeftBar",
  componentId: "sc-184iqnz-1"
})(["flex-shrink:0;@media screen and (min-width:", "){width:29.6rem;padding-right:", ";}@media screen and (min-width:", "){width:32.8rem;}"], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.spacing['60_Large'], ({
  theme
}) => theme.mediaQueries.xl);
const RightItems = _styledComponents.default.div.withConfig({
  displayName: "SearchResults__RightItems",
  componentId: "sc-184iqnz-2"
})(["display:flex;margin-left:auto;align-items:center;justify-content:flex-end;margin-bottom:2rem;> * + *{margin-left:2rem;}"]);
const ResultsContainer = _styledComponents.default.div.withConfig({
  displayName: "SearchResults__ResultsContainer",
  componentId: "sc-184iqnz-3"
})(["flex:1;"]);