"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var __jsx = _react.default.createElement;
const tripTypeLabel = {
  EARLY_BOOKING: __jsx(_reactIntl.FormattedMessage, {
    id: "u374mB",
    defaultMessage: [{
      "type": 0,
      "value": "Vroegboekkorting aanbiedingen"
    }]
  }),
  LAST_MINUTE: __jsx(_reactIntl.FormattedMessage, {
    id: "9bgR3Q",
    defaultMessage: [{
      "type": 0,
      "value": "Last-minute aanbiedingen"
    }]
  })
};
var _default = exports.default = tripTypeLabel;