"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var _grid = require("@rebass/grid");
var _formik = require("formik");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _searchOrderMessages = _interopRequireDefault(require("../constants/searchOrderMessages"));
var __jsx = _react.default.createElement;
const SearchOrderSelectInput = ({
  name = 'order',
  searchOrder
}) => {
  const {
    formatMessage
  } = (0, _reactIntl.useIntl)();
  const [{
    value
  },, {
    setValue
  }] = (0, _formik.useField)(name);
  const onChangeHandler = val => {
    setValue(val);
  };
  if (Object.values(searchOrder).length === 0) {
    return null;
  }
  return __jsx(SorterContainer, {
    className: "gt-l"
  }, __jsx(StyledBox, {
    className: "gt-l",
    mr: 3
  }, __jsx("span", null, __jsx(_reactIntl.FormattedMessage, {
    id: "oNKL5g",
    defaultMessage: [{
      "type": 0,
      "value": "Gesorteerd op"
    }]
  }), ":")), __jsx(SorterSelect, null, Object.keys(searchOrder).map(key => __jsx(_grid.Box, {
    key: key,
    mr: 3
  }, __jsx(StyledButton, {
    size: "small",
    onClick: () => onChangeHandler(searchOrder[key]),
    variant: value === searchOrder[key] ? 'primary' : 'outline'
  }, formatMessage(_searchOrderMessages.default[key]))))));
};
var _default = exports.default = SearchOrderSelectInput;
const SorterContainer = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "SearchOrderSelectInput__SorterContainer",
  componentId: "sc-2ysoim-0"
})(["padding-left:", ";height:3.2rem;align-items:baseline;flex-shrink:0;@media only screen and (min-width:", "){flex:1;}font-size:", ";> p{color:", ";}"], ({
  theme
}) => theme.spacing['30_Small'], ({
  theme
}) => theme.mediaQueries.l, ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.body.small), ({
  theme
}) => theme.colors.neutral['50']);
const StyledBox = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "SearchOrderSelectInput__StyledBox",
  componentId: "sc-2ysoim-1"
})(["> span{white-space:nowrap;}"]);
const StyledButton = (0, _styledComponents.default)(_Button.default).withConfig({
  displayName: "SearchOrderSelectInput__StyledButton",
  componentId: "sc-2ysoim-2"
})(["@media only screen and (min-width:", "){> span{white-space:nowrap;}}"], ({
  theme
}) => theme.mediaQueries.l);
const SorterSelect = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "SearchOrderSelectInput__SorterSelect",
  componentId: "sc-2ysoim-3"
})([""]);