"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.query = exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _react = _interopRequireWildcard(require("react"));
var _graphql = require("../../generated/graphql");
var _trip = require("../../utils/trip");
var _search = require("../../utils/search");
var _getDefaultAccommodationType = _interopRequireDefault(require("../../utils/getDefaultAccommodationType"));
var _searchBoxValuesFromStorage = require("../../utils/searchBoxValuesFromStorage");
var _useQueryParams = require("use-query-params");
var _searchParams = _interopRequireDefault(require("../../constants/searchParams"));
var _SearchResults = _interopRequireDefault(require("../SearchResults"));
var _TenantContext = require("../../context/TenantContext");
var _removeUndefinedEntries = _interopRequireDefault(require("../../utils/removeUndefinedEntries"));
const _excluded = ["startParams", "bakedInParams", "ignoreStorage", "defaultAccommodationType", "amountPerPage"],
  _excluded2 = ["accommodationType", "order", "offset", "andFilters", "orFilters", "bbLeft", "bbRight", "bbTop", "bbBottom"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const query = exports.query = (0, _graphqlTag.default)`
    query SearchPageRentalUnits(
        $babies: Int!
        $filters: [PropertyFilterInput!]
        $persons: Int!
        $pets: Int!
        $mapArea: BoundingBoxInput
        $offset: Int
        $order: SearchRentalUnitsOrderEnum
        $limit: Int
        $badgeHandle: String!
        $badge2Handle: String!
    ) {
        searchRentalUnits(
            babies: $babies
            filters: $filters
            persons: $persons
            pets: $pets
            mapArea: $mapArea
            limit: $limit
            offset: $offset
            order: $order
        ) {
            hits {
                ...SearchHitRentalUnit
            }
            propertyFilterCounts {
                ...PropertyFilterCounts
            }
            totalHits
        }
    }
`;
const SearchRentalUnits = _ref => {
  let {
      startParams = {},
      bakedInParams = {},
      ignoreStorage,
      defaultAccommodationType,
      amountPerPage
    } = _ref,
    rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const initialStorageVals = !ignoreStorage ? (0, _searchBoxValuesFromStorage.getSearchBoxValuesFromStorage)() : undefined;
  const [params] = (0, _useQueryParams.useQueryParams)(_searchParams.default);
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const _withDefaultBookingVa = (0, _trip.withDefaultBookingValues)(
    // merge initial params and current params and overwrite it with bakedIn params
    (0, _search.mergeParams)(startParams, initialStorageVals, (0, _removeUndefinedEntries.default)(params), bakedInParams, brandConfig.bakedInFilterProperty)),
    {
      accommodationType = defaultAccommodationType ?? (0, _getDefaultAccommodationType.default)(brandConfig),
      order = brandConfig.unitSearchOrder.popularity,
      offset = 0,
      andFilters = [],
      orFilters = [],
      bbLeft,
      bbRight,
      bbTop,
      bbBottom
    } = _withDefaultBookingVa,
    booking = (0, _objectWithoutProperties2.default)(_withDefaultBookingVa, _excluded2);
  const filters = (0, _react.useMemo)(() => (0, _search.convertToFilters)([andFilters, 'AND'], [(0, _search.getAccommodationFilters)(accommodationType), 'OR'], [orFilters, 'OR']), [accommodationType, andFilters, orFilters]);
  const paramsInput = (0, _trip.convertBookingToParamsInput)(booking, {
    filters
  });
  const mapArea = bbBottom && bbTop && bbLeft && bbRight ? {
    bottom: bbBottom,
    top: bbTop,
    left: bbLeft,
    right: bbRight
  } : null;
  const {
    data,
    loading
  } = (0, _graphql.useSearchPageRentalUnitsQuery)({
    ssr: false,
    variables: _objectSpread(_objectSpread({
      mapArea
    }, paramsInput), {}, {
      // some protection as an offset < 0 will let the BE explode 🧨
      offset: offset >= 0 ? offset : 0,
      limit: amountPerPage,
      order: Object.values(_graphql.SearchRentalUnitsOrderEnum).find(value => value === order) ?? brandConfig.unitSearchOrder.popularity,
      badgeHandle: brandConfig.badge?.handle ?? '',
      badge2Handle: brandConfig.badge2?.handle ?? ''
    })
  });
  const {
    hits,
    propertyFilterCounts,
    totalHits
  } = data?.searchRentalUnits ?? {};
  return __jsx(_SearchResults.default, (0, _extends2.default)({
    amountPerPage: amountPerPage,
    loading: loading,
    propertyFilterCounts: propertyFilterCounts,
    totalHits: totalHits,
    defaultAccommodationType: defaultAccommodationType,
    searchOrder: brandConfig.unitSearchOrder,
    ignoreStorage: ignoreStorage,
    lastSearchParams: paramsInput,
    startParams: startParams,
    bakedInParams: bakedInParams,
    data: hits?.map(hit => ({
      trips: [],
      rentalUnit: hit
    })) ?? []
  }, rest));
};
var _default = exports.default = SearchRentalUnits;