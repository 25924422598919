"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ArrowRight = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/ArrowRight"));
var _devicesize = require("@oberoninternal/travelbase-ds/context/devicesize");
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _grid = require("@rebass/grid");
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _reactSpring = require("react-spring");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _TenantContext = require("../context/TenantContext");
var _Stack = _interopRequireDefault(require("./Stack"));
var _WaterlandPompebled = _interopRequireDefault(require("./svg/WaterlandPompebled.svg"));
var _UspBody = _interopRequireDefault(require("./UspBody"));
var _UspsModal = _interopRequireDefault(require("./UspsModal"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const noIntervalDevices = ['laptop', 'desktop'];
const INTERVAL = 3000;
const Usps = ({
  className
}) => {
  const modal = (0, _useSesame.default)();
  const deviceSize = (0, _devicesize.useDeviceSize)();
  const {
    0: visibleIndex,
    1: setVisibleIndex
  } = (0, _react.useState)(null);
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const usps = brandConfig.usps ?? [];

  // loop through usps in sequence and set visibleIndex to the index of the visible usp to get that slideshow effect
  (0, _react.useEffect)(() => {
    if (deviceSize && noIntervalDevices.includes(deviceSize)) {
      return undefined;
    }
    const interval = setInterval(() => {
      setVisibleIndex(index => {
        if (index === null) {
          return 0;
        }
        if (index === usps.length - 1) {
          return null;
        }
        return index + 1;
      });
    }, INTERVAL);
    return () => clearInterval(interval);
  }, [deviceSize, usps.length]);
  if (!usps.length) return null;
  return __jsx(Container, {
    className: className
  }, __jsx(_grid.Flex, {
    onClick: modal.onOpen,
    as: "button",
    justifyContent: "center",
    className: "lt-l",
    style: {
      position: 'relative',
      height: '3rem',
      overflow: 'hidden'
    },
    p: 0,
    width: 1
  }, __jsx(FadeInOut, {
    visible: visibleIndex === null
  }, __jsx(_Stack.default, {
    variant: "inline",
    justifyContent: "center",
    alignItems: "center",
    spacing: 3
  }, __jsx(_WaterlandPompebled.default, null), __jsx(_UspBody.default, {
    variant: "large"
  }, __jsx(_reactIntl.FormattedMessage, {
    values: {
      count: usps.length
    },
    id: "lyuDhN",
    defaultMessage: [{
      "type": 1,
      "value": "count"
    }, {
      "type": 0,
      "value": " redenen om bij ons te boeken!"
    }]
  })), __jsx(_ArrowRight.default, null))), usps.map(({
    title
  }, index) => __jsx(FadeInOut, {
    key: index,
    visible: visibleIndex === index
  }, __jsx(_UspBody.default, {
    variant: "large"
  }, title)))), __jsx(List, {
    className: "gt-l",
    onClick: modal.onOpen
  }, usps.map(({
    title
  }, index) => __jsx(ClickableUsp, {
    onClick: () => {
      setVisibleIndex(index);
      modal.onOpen();
    },
    key: index
  }, __jsx(_UspBody.default, {
    variant: "large"
  }, title)))), __jsx(_UspsModal.default, {
    onClose: modal.onClose,
    open: modal.open,
    visibleIndex: visibleIndex ?? 0
  }));
};
var _default = exports.default = Usps;
const FadeInOut = ({
  visible,
  children
}) => {
  const styles = (0, _reactSpring.useSpring)({
    transform: visible ? 'translateY(0%)' : 'translateY(150%)'
  });
  return __jsx(StyledDiv, {
    style: styles
  }, children);
};
const StyledDiv = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "Usps__StyledDiv",
  componentId: "hjgl1c-0"
})(["position:absolute;color:", ";"], ({
  theme
}) => theme.colors.secondary[50]);
const Container = _styledComponents.default.div.withConfig({
  displayName: "Usps__Container",
  componentId: "hjgl1c-1"
})(["width:100%;background:", ";padding:", " 0;"], ({
  theme
}) => theme.colors.secondary[0], ({
  theme
}) => theme.spacing['40_Standard']);
const List = _styledComponents.default.div.withConfig({
  displayName: "Usps__List",
  componentId: "hjgl1c-2"
})(["display:flex;padding:0;gap:", ";justify-content:center;width:100%;"], ({
  theme
}) => theme.spacing['60_Large']);
const ClickableUsp = _styledComponents.default.button.withConfig({
  displayName: "Usps__ClickableUsp",
  componentId: "hjgl1c-3"
})(["display:flex;padding:0;gap:", ";color:", ";align-items:center;"], ({
  theme
}) => theme.spacing['30_Small'], ({
  theme
}) => theme.colors.secondary[50]);