"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fragment = exports.default = void 0;
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _client = require("@apollo/client");
var _formik = require("formik");
var _lodash = _interopRequireDefault(require("lodash.isequal"));
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Slider = _interopRequireDefault(require("./designsystem/Slider"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const fragment = exports.fragment = (0, _client.gql)`
    fragment SpecialCount on UnitSearchResult {
        specialCounts {
            special {
                description
                id
                landingPageUrl
                name
            }
            hits
        }
    }

    fragment TripTypeCount on UnitSearchResult {
        tripTypeCounts {
            hits
            tripType
        }
    }
`;
const PriceFilter = ({
  minPrice = 0,
  maxPrice = 0
}) => {
  const sameMinAndMax = minPrice === maxPrice;
  if (sameMinAndMax) {
    return null;
  }
  return __jsx(_react.default.Fragment, null, __jsx(FilterCaption, null, __jsx(_reactIntl.FormattedMessage, {
    id: "nQLq/E",
    defaultMessage: [{
      "type": 0,
      "value": "Totaalprijs"
    }]
  })), __jsx(PriceFilterSlider, {
    minPrice: minPrice,
    maxPrice: maxPrice
  }));
};
var _default = exports.default = PriceFilter;
const PriceFilterSlider = ({
  minPrice,
  maxPrice
}) => {
  const [{
    value
  }, meta, {
    setValue,
    setTouched
  }] = (0, _formik.useField)('price');
  const {
    0: currentPriceRange,
    1: setPriceRange
  } = (0, _react.useState)(value);
  const {
    0: isChanging,
    1: setIsChanging
  } = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    if (!isChanging && !(0, _lodash.default)(value, currentPriceRange)) {
      setPriceRange(value);
    }
  }, [currentPriceRange, isChanging, value]);
  const onAfterChange = val => {
    setIsChanging(false);
    setValue(val);
    if (!meta.touched) {
      setTouched(true);
    }
  };
  const [start, end] = currentPriceRange;
  return __jsx(_react.default.Fragment, null, __jsx(StyledSliderField, {
    minDistance: 10,
    min: minPrice,
    max: maxPrice,
    defaultValue: meta.initialValue ?? value,
    value: currentPriceRange,
    onBeforeChange: () => setIsChanging(true),
    onChange: setPriceRange // why tho
    ,
    onAfterChange: onAfterChange
  }), __jsx(SliderBody, null, __jsx(_reactIntl.FormattedMessage, {
    id: "LEPbBs",
    defaultMessage: [{
      "type": 0,
      "value": "Van "
    }, {
      "type": 1,
      "value": "minPrice"
    }, {
      "type": 0,
      "value": " tot "
    }, {
      "type": 1,
      "value": "maxPrice"
    }],
    values: {
      minPrice: __jsx(_reactIntl.FormattedNumber, {
        format: "EUR_ROUNDED",
        value: Math.floor(start)
      }),
      maxPrice: __jsx(_reactIntl.FormattedNumber, {
        format: "EUR_ROUNDED",
        value: Math.ceil(end)
      })
    }
  })));
};
const FilterCaption = (0, _styledComponents.default)(_Body.default).attrs({
  variant: 'micro'
}).withConfig({
  displayName: "PriceFilter__FilterCaption",
  componentId: "sc-3xykzq-0"
})(["font-weight:bold;margin-bottom:", ";"], ({
  theme
}) => theme.spacing['40_Standard']);
const StyledSliderField = (0, _styledComponents.default)(_Slider.default).withConfig({
  displayName: "PriceFilter__StyledSliderField",
  componentId: "sc-3xykzq-1"
})(["@media screen and (max-width:", "px){width:100%;}"], ({
  theme
}) => theme.mediaQueriesValues.s);
const SliderBody = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "PriceFilter__SliderBody",
  componentId: "sc-3xykzq-2"
})(["font-weight:600;font-size:1.5rem;text-align:center;margin:", " 0;"], ({
  theme
}) => theme.spacing['40_Standard']);