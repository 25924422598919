"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _ModalHeader = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/ModalHeader"));
var _formik = require("formik");
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _TenantContext = require("../context/TenantContext");
var _fp = require("../utils/fp");
var _CheckboxFilter = _interopRequireDefault(require("./CheckboxFilter"));
var _Formed = _interopRequireDefault(require("./Formed"));
var _RadioFilter = _interopRequireDefault(require("./RadioFilter"));
var _Stack = _interopRequireDefault(require("./Stack"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const isPlaceFilter = val => val.startsWith('_place');
const SearchPlacesModal = ({
  allProperties,
  open,
  onClose
}) => {
  const {
    setFieldValue,
    values: {
      orFilters,
      andFilters
    }
  } = (0, _formik.useFormikContext)();
  const placesFilterCategory = (0, _react.useMemo)(() => allProperties?.find(property => isPlaceFilter(property.handle)), [allProperties]);
  const {
    brandConfig: {
      placeFilter
    }
  } = (0, _TenantContext.useTenantContext)();
  return __jsx(_Modal.default, {
    open: open,
    onClose: onClose,
    variant: "regular"
  }, __jsx(_ModalHeader.default, null, __jsx(_reactIntl.FormattedMessage, {
    id: "Ook6o3",
    defaultMessage: [{
      "type": 0,
      "value": "Plaatsen toevoegen"
    }]
  })), __jsx(_Formed.default, {
    skipPrompt: true,
    initialValues: {
      places: orFilters.filter(isPlaceFilter)
    },
    handleSubmit: values => {
      if (placeFilter?.type === 'AND') {
        setFieldValue('andFilters', [...andFilters.filter((0, _fp.not)(isPlaceFilter)), ...values.places]);
      } else {
        setFieldValue('orFilters', [...orFilters.filter((0, _fp.not)(isPlaceFilter)), ...values.places]);
      }
    }
  }, ({
    submitForm
  }) => __jsx(_Stack.default, {
    my: 3,
    spacing: 4
  }, placesFilterCategory?.filterProperties.map(place => placeFilter?.type === 'AND' ? __jsx(_RadioFilter.default, {
    key: place.handle,
    id: place.handle,
    label: place.name,
    categoryHandle: placesFilterCategory.handle,
    fieldName: "places",
    fieldValue: `_place.${place.handle}`
  }) : __jsx(_CheckboxFilter.default, {
    key: place.handle,
    id: place.handle,
    label: place.name,
    fieldName: "places",
    fieldValue: `_place.${place.handle}`
  })), __jsx(_Button.default, {
    onClick: () => {
      onClose();
      submitForm();
    },
    type: "button",
    style: {
      width: '100%'
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "Ook6o3",
    defaultMessage: [{
      "type": 0,
      "value": "Plaatsen toevoegen"
    }]
  })))));
};
var _default = exports.default = SearchPlacesModal;