"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.query = exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _react = _interopRequireWildcard(require("react"));
var _graphql = require("../../generated/graphql");
var _trip = require("../../utils/trip");
var _search = require("../../utils/search");
var _getDefaultAccommodationType = _interopRequireDefault(require("../../utils/getDefaultAccommodationType"));
var _searchBoxValuesFromStorage = require("../../utils/searchBoxValuesFromStorage");
var _useQueryParams = require("use-query-params");
var _searchParams = _interopRequireDefault(require("../../constants/searchParams"));
var _SearchResults = _interopRequireDefault(require("../SearchResults"));
var _TenantContext = require("../../context/TenantContext");
var _parse = _interopRequireDefault(require("date-fns/parse"));
var _eachDayOfInterval = _interopRequireDefault(require("date-fns/eachDayOfInterval"));
var _startOfMonth = _interopRequireDefault(require("date-fns/startOfMonth"));
var _endOfMonth = _interopRequireDefault(require("date-fns/endOfMonth"));
var _parseToDateString = _interopRequireDefault(require("../../constants/parseToDateString"));
var _add = _interopRequireDefault(require("date-fns/add"));
var _isWeekend = _interopRequireDefault(require("date-fns/isWeekend"));
var _removeUndefinedEntries = _interopRequireDefault(require("../../utils/removeUndefinedEntries"));
const _excluded = ["startParams", "bakedInParams", "ignoreStorage", "defaultAccommodationType", "amountPerPage"],
  _excluded2 = ["offset", "accommodationType", "order", "andFilters", "orFilters", "tripType", "specialId", "minPrice", "maxPrice", "bbLeft", "bbRight", "bbTop", "bbBottom"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const query = exports.query = (0, _graphqlTag.default)`
    query SearchTrips(
        $babies: Int!
        $date: [String!]!
        $duration: [Int!]!
        $filters: [PropertyFilterInput!]
        $persons: Int!
        $pets: Int!
        $price: RangeFilterInput
        $specialId: [ID!]
        $tripType: [TripTypeEnum!]
        $mapArea: BoundingBoxInput
        $limit: Int
        $offset: Int
        $order: UnitSearchOrderEnum
        $badgeHandle: String!
        $badge2Handle: String!
    ) {
        searchTrips(
            babies: $babies
            date: $date
            duration: $duration
            filters: $filters
            persons: $persons
            pets: $pets
            price: $price
            specialId: $specialId
            tripType: $tripType
            mapArea: $mapArea
        ) {
            hits(limit: $limit, offset: $offset, order: $order) {
                ...SearchHitTrip
                rentalUnit {
                    ...SearchHitRentalUnit
                }
            }

            # TODO jordi rightfully says: niet het veld stats (opnieuw) opvragen bij elke keer dat je naar de volgende pagina resultaten bladert.
            stats {
                totalHits
                propertyFilterCounts {
                    ...PropertyFilterCounts
                }
                minPrice
                maxPrice
                tripTypeCounts {
                    tripType
                    hits
                }
                specialCounts {
                    special {
                        description
                        id
                        landingPageUrl
                        name
                    }
                    hits
                }
            }
        }
    }

    fragment ListImage on ImageTransform {
        placeholder
        srcSet
        src
        ratio
    }

    query UnitFilterProperties {
        unitFilterProperties {
            name
            handle
            filterProperties {
                name
                handle
            }
        }
    }

    query UnitSearchPriceFilter($params: UnitSearchParamsInput!) {
        unitSearch(params: $params) {
            minPrice
            maxPrice
        }
    }

    fragment PropertyFilterCounts on PropertyFilterCount {
        hits
        property
    }
`;
const getFlexibleDates = (type, month) => {
  const parsedMonth = (0, _parse.default)(month, 'MM-yyyy', new Date());
  const interval = {
    start: (0, _startOfMonth.default)(parsedMonth),
    end: (0, _endOfMonth.default)(parsedMonth)
  };
  let dates = (0, _eachDayOfInterval.default)(interval);
  if (type === 'flexible-weekends') {
    dates = dates.filter(_isWeekend.default);
  }
  return dates.map(_parseToDateString.default);
};
const SearchTrips = _ref => {
  let {
      startParams = {},
      bakedInParams = {},
      ignoreStorage,
      defaultAccommodationType,
      amountPerPage
    } = _ref,
    rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const initialStorageVals = !ignoreStorage ? (0, _searchBoxValuesFromStorage.getSearchBoxValuesFromStorage)() : undefined;
  const [params] = (0, _useQueryParams.useQueryParams)(_searchParams.default);
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const _withDefaultBookingVa = (0, _trip.withDefaultBookingValues)(
    // merge initial params and current params and overwrite it with bakedIn params
    (0, _search.mergeParams)(startParams, initialStorageVals, (0, _removeUndefinedEntries.default)(params), bakedInParams, brandConfig.bakedInFilterProperty)),
    {
      offset = 0,
      accommodationType = defaultAccommodationType ?? (0, _getDefaultAccommodationType.default)(brandConfig),
      order = brandConfig.tripsSearchOrder.popularity,
      andFilters = [],
      orFilters = [],
      tripType,
      specialId,
      minPrice,
      maxPrice,
      bbLeft,
      bbRight,
      bbTop,
      bbBottom
    } = _withDefaultBookingVa,
    booking = (0, _objectWithoutProperties2.default)(_withDefaultBookingVa, _excluded2);
  const {
    amountBabies,
    amountPets,
    amountAdults,
    amountChildren,
    amountYouths,
    type,
    amountOfNights,
    flexibleMonth,
    arrivalDate,
    departureDate
  } = booking;
  const filters = (0, _react.useMemo)(() => (0, _search.convertToFilters)([andFilters, 'AND'], [(0, _search.getAccommodationFilters)(accommodationType), 'OR'], [orFilters, 'OR']), [accommodationType, andFilters, orFilters]);
  const mapArea = bbBottom && bbTop && bbLeft && bbRight ? {
    bottom: bbBottom,
    top: bbTop,
    left: bbLeft,
    right: bbRight
  } : null;
  const isFlexible = type !== 'static' && amountOfNights && flexibleMonth;
  const isComingMonth = type === 'coming-month';
  const paramsInput = (0, _trip.convertBookingToParamsInput)(booking, {
    filters
  });
  const getComingMonthsDate = () => {
    const interval = {
      start: new Date(),
      end: (0, _add.default)(new Date(), {
        months: 1
      })
    };
    const dates = (0, _eachDayOfInterval.default)(interval);
    return dates.map(_parseToDateString.default);
  };
  const vars = {
    mapArea,
    filters,
    tripType: tripType,
    specialId,
    pets: amountPets,
    persons: amountAdults + amountChildren + amountYouths,
    babies: amountBabies,
    price: {
      min: minPrice,
      max: maxPrice
    },
    duration: isComingMonth ? [1, 2, 3, 4, 5, 6, 7] : isFlexible ? amountOfNights : (0, _trip.getTripDuration)(arrivalDate, departureDate),
    date: isComingMonth ? getComingMonthsDate() : isFlexible ? getFlexibleDates(type, flexibleMonth) : arrivalDate,
    // some protection as an offset < 0 will let the BE explode 🧨
    offset: offset >= 0 ? offset : 0,
    limit: amountPerPage,
    order: Object.values(_graphql.UnitSearchOrderEnum).find(value => value === order) ?? brandConfig?.defaultTripSearchOrderByRentalUnitType?.[accommodationType] ?? brandConfig.tripsSearchOrder.popularity,
    badgeHandle: brandConfig.badge?.handle ?? '',
    badge2Handle: brandConfig.badge2?.handle ?? ''
  };
  const {
    data,
    loading
  } = (0, _graphql.useSearchTripsQuery)({
    ssr: false,
    variables: vars
  });
  const stats = data?.searchTrips?.stats;
  const hits = data?.searchTrips?.hits;
  return __jsx(_SearchResults.default, (0, _extends2.default)({
    amountPerPage: amountPerPage,
    loading: loading,
    tripTypeCounts: stats?.tripTypeCounts,
    propertyFilterCounts: stats?.propertyFilterCounts,
    specialCounts: stats?.specialCounts,
    totalHits: stats?.totalHits,
    defaultAccommodationType: defaultAccommodationType,
    searchOrder: brandConfig.tripsSearchOrder,
    minPrice: stats?.minPrice,
    maxPrice: stats?.maxPrice,
    lastSearchParams: paramsInput,
    startParams: startParams,
    bakedInParams: bakedInParams,
    ignoreStorage: ignoreStorage,
    order: vars.order,
    data: hits?.map(hit => ({
      trips: [hit],
      rentalUnit: hit.rentalUnit
    })) ?? []
  }, rest));
};
var _default = exports.default = SearchTrips;