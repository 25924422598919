"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Radio = require("@oberoninternal/travelbase-ds/components/form/Radio");
var _ModalHeader = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/ModalHeader"));
var _grid = require("@rebass/grid");
var _formik = require("formik");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _Stack = _interopRequireDefault(require("./Stack"));
var _searchOrderMessages = _interopRequireDefault(require("../constants/searchOrderMessages"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var __jsx = _react.default.createElement;
const SearchSortModal = props => {
  const {
    submitForm
  } = (0, _formik.useFormikContext)();
  return __jsx(_Modal.default, props, __jsx(_ModalHeader.default, {
    style: {
      textTransform: 'capitalize'
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "Y1jaCK",
    defaultMessage: [{
      "type": 0,
      "value": "sorteren"
    }]
  })), __jsx(_Stack.default, {
    my: 6,
    spacing: 4
  }, Object.entries(props.searchOrder).map(([key, value]) => __jsx(_grid.Flex, {
    key: key,
    alignItems: "center"
  }, __jsx(_Radio.RadioField, {
    name: "order",
    id: value
  }), __jsx("label", {
    htmlFor: value
  }, __jsx(_reactIntl.FormattedMessage, _searchOrderMessages.default[key]))))), __jsx(_Button.default, {
    style: {
      width: '100%'
    },
    onClick: () => {
      props.onClose();
      setTimeout(() => {
        /* As the modal is rendered through a portal, the form won't be automatically submitted */
        submitForm();
      });
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "QNDRr3",
    defaultMessage: [{
      "type": 0,
      "value": "Sortering toepassen"
    }]
  })));
};
var _default = exports.default = SearchSortModal;