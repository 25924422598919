"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _useQueryParams = require("use-query-params");
var _searchParams = _interopRequireDefault(require("../constants/searchParams"));
var _SearchTrips = _interopRequireDefault(require("./pages/SearchTrips"));
var _SearchRentalUnits = _interopRequireDefault(require("./pages/SearchRentalUnits"));
var _react = _interopRequireDefault(require("react"));
var _search = require("../utils/search");
var _removeUndefinedEntries = _interopRequireDefault(require("../utils/removeUndefinedEntries"));
var _searchBoxValuesFromStorage = require("../utils/searchBoxValuesFromStorage");
var _TenantContext = require("../context/TenantContext");
var __jsx = _react.default.createElement;
const getSearchPageComponent = mergedParams => {
  switch (mergedParams.type) {
    case 'flexible-random':
    case 'flexible-weekends':
    case 'coming-month':
      return _SearchTrips.default;
    default:
    case 'static':
      if (mergedParams.arrivalDate && mergedParams.departureDate || mergedParams.period) {
        return _SearchTrips.default;
      }
      return _SearchRentalUnits.default;
  }
};
const Search = props => {
  const [params] = (0, _useQueryParams.useQueryParams)(_searchParams.default);
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const initialStorageVals = !props.ignoreStorage ? (0, _searchBoxValuesFromStorage.getSearchBoxValuesFromStorage)() : undefined;
  const mergedParams = (0, _search.mergeParams)(props.startParams, initialStorageVals, (0, _removeUndefinedEntries.default)(params), props.bakedInParams, brandConfig.bakedInFilterProperty);
  const SearchPage = getSearchPageComponent(mergedParams);
  return __jsx(SearchPage, props);
};
var _default = exports.default = Search;