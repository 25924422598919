import styled from 'styled-components';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';
import { Box } from '@rebass/grid';

const SearchWrapper = styled(Box)`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        ${ContentWrapper} {
            padding-left: calc(var(--min-padding) * 1px + 2.4rem) !important;
            padding-right: calc(var(--min-padding) * 1px + 2.4rem) !important;
            max-width: none;
        }
    }
    [class*='Grid-'] {
        & h2 {
            font-size: 1.4rem;
            line-height: 1.5;
            margin-bottom: 0.4rem;
            @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
                font-size: 1.6rem;
            }
            font-weight: 400;
            position: relative;
            padding-top: ${({ theme }) => theme.spacing['40_Standard']};

            &::before {
                content: '';
                width: ${({ theme }) => theme.spacing['50_Semi']};
                height: ${({ theme }) => theme.spacing['10_Micro']};
                background-color: ${({ theme }) => theme.colors.secondary['30']};
                position: absolute;
                border-radius: 1.6rem;
                top: 0;
                left: 0;
            }
        }
        padding-bottom: 4rem;
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
            padding-bottom: 6.4rem;
        }
    }
    [class*='Rating__Container-'] {
        align-items: center;
        > svg {
            width: 1.4rem;
            margin-top: -0.2rem;
        }
    }
    [class*='UnitTile__Container-'] {
        margin-bottom: 5.6rem;
    }
`;

export default SearchWrapper;
