"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactIntl = require("react-intl");
const searchOrderMessages = (0, _reactIntl.defineMessages)({
  popularity: {
    "id": "00mBZa",
    "defaultMessage": [{
      "type": 0,
      "value": "Populariteit"
    }]
  },
  ratingDesc: {
    "id": "ZzKaCE",
    "defaultMessage": [{
      "type": 0,
      "value": "Beoordeling hoog / laag"
    }]
  },
  priceAsc: {
    "id": "q5dY5Z",
    "defaultMessage": [{
      "type": 0,
      "value": "Prijs laag / hoog"
    }]
  },
  priceDesc: {
    "id": "QdZ9KF",
    "defaultMessage": [{
      "type": 0,
      "value": "Prijs hoog / laag"
    }]
  }
});
var _default = exports.default = searchOrderMessages;