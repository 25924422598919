"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _CheckboxFilter = _interopRequireDefault(require("./CheckboxFilter"));
var _FilterList = _interopRequireDefault(require("./FilterList"));
var _PlacesFilter = _interopRequireDefault(require("./PlacesFilter"));
var _PriceFilter = _interopRequireDefault(require("./PriceFilter"));
var _Special = _interopRequireDefault(require("@oberoninternal/travelbase-ds/svg/Special.svg"));
var _grid = require("@rebass/grid");
var _Divider = _interopRequireDefault(require("./Divider"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _FilterListCategory = _interopRequireDefault(require("./FilterListCategory"));
var _messages = _interopRequireDefault(require("../constants/messages"));
var __jsx = _react.default.createElement;
const AccommodationSearchFilters = ({
  allProperties,
  hidePriceFilter,
  maximalMaxPrice,
  minimalMinPrice,
  specialCategories,
  unitFilterCategories,
  propertyFilterCounts
}) => {
  const flexCancellationHits = propertyFilterCounts.find(filter => filter.property === '_pricing.flex-cancellation')?.hits;
  const brandCategory = allProperties?.find(category => category.handle === '_brand');
  const multipleBrands = (brandCategory?.filterProperties?.length ?? 0) > 1;
  const intl = (0, _reactIntl.useIntl)();
  return __jsx(_react.default.Fragment, null, !!specialCategories.length && specialCategories.some(special => !!special.filters?.length) && __jsx(_react.default.Fragment, null, __jsx(_grid.Flex, {
    alignItems: "center"
  }, __jsx(_grid.Box, {
    mr: 3
  }, __jsx(_Special.default, null)), __jsx(_grid.Box, null, __jsx(FilterCaption, {
    variant: "micro"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "CQa3R9",
    defaultMessage: [{
      "type": 0,
      "value": "Deals"
    }]
  })))), specialCategories.map(category => category?.filters?.map(filter => __jsx(_CheckboxFilter.default, {
    key: filter.handle,
    label: filter.label,
    fieldValue: filter.handle,
    fieldName: category.formikKey ?? category.handle,
    id: filter.handle,
    hits: filter.hits
  }))), __jsx(_Divider.default, null)), !hidePriceFilter && minimalMinPrice !== maximalMaxPrice && __jsx(_react.default.Fragment, null, __jsx(_PriceFilter.default, {
    minPrice: minimalMinPrice,
    maxPrice: maximalMaxPrice
  }), !specialCategories.length && __jsx(_Divider.default, null)), !!flexCancellationHits && __jsx(_react.default.Fragment, null, __jsx(_CheckboxFilter.default, {
    label: intl.formatMessage(_messages.default.flexCancellation),
    fieldValue: "_pricing.flex-cancellation",
    fieldName: "orFilters",
    id: "_pricing.flex-cancellation",
    hits: flexCancellationHits
  }), __jsx(_Divider.default, null)), __jsx(_FilterList.default, {
    categories: unitFilterCategories,
    categoryComponents: {
      'type-of-accommodation': ({
        category
      }) => __jsx(_FilterListCategory.default, {
        category: category,
        type: "radio"
      }),
      _brand: ({
        category
      }) => {
        if (!multipleBrands) {
          return null;
        }
        return __jsx(_FilterListCategory.default, {
          category: category
        });
      },
      _place: props => {
        if (multipleBrands) {
          return null;
        }
        return __jsx(_PlacesFilter.default, (0, _extends2.default)({
          allProperties: allProperties
        }, props));
      }
    }
  }));
};
const FilterCaption = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "AccommodationSearchFilters__FilterCaption",
  componentId: "sc-1xlzpuc-0"
})(["font-weight:bold;"]);
var _default = exports.default = AccommodationSearchFilters;